/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js
 * - /npm/owlcarousel@1.3.3/owl-carousel/owl.carousel.min.js
 * - /npm/jquery.cycle2@2.1.7/src/jquery.cycle2.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/tingle.js@0.16.0/dist/tingle.min.js
 * - /npm/jssocials@1.5.0/dist/jssocials.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
